angular.module('app').service('s3Service', ['$http', '$q', function($http, $q){
	var service = this;
	
	service.getCredentials = function () {
		var creds_deferred = $q.defer();

		$http.get('/api/s3access/getCredentials').
		success(function (response, status) {
			service.credentials = response;
			creds_deferred.resolve(response);
		}).error(function (error, status) {
			creds_deferred.reject(error);
		});

		return creds_deferred.promise;
	};

	service.deleteFile = function(key, bucket){
		var delete_deferred = $q.defer();
		
		service.getCredentials()
		.then(function ( credentials ){

			AWS.config.update(credentials);
			AWS.config.region = 'us-east-1';			

			var s3 = new AWS.S3();


			s3.deleteObject({Key: key, Bucket: bucket }, function(err, data){
				if(err) console.log(err, err.stack);
				else console.log(data);
				delete_deferred.resolve();	
			});
		});

		return delete_deferred.promise;
	};

	service.deleteFiles = function(keys, bucket){
		var delete_deferred = $q.defer();
		
		service.getCredentials()
		.then(function ( credentials ){

			AWS.config.update(credentials);
			AWS.config.region = 'us-east-1';			

			var s3 = new AWS.S3();


			s3.deleteObjects( 
				{ Delete: { Objects: keys }, Bucket: bucket, }, function(err, data){
				if(err) console.log(err, err.stack);
				else console.log(data);
				delete_deferred.resolve();	
			});
		});

		return delete_deferred.promise;
	};	

}]);