/**
 * Config for the router
 */
angular.module('app')
  .run(
    [          '$rootScope', '$state', '$stateParams',
      function ($rootScope,   $state,   $stateParams) {
          $rootScope.$state = $state;
          $rootScope.$stateParams = $stateParams;        
      }
    ]
  )
  .config(
    [          '$stateProvider', '$urlRouterProvider', 'JQ_CONFIG', 
      function ($stateProvider,   $urlRouterProvider, JQ_CONFIG) {
          
          $urlRouterProvider.otherwise(function ($injector) {
            var $state = $injector.get('$state');
            $state.go('app.dashboard.home');
          });


/***************************************************************************************
 APP
***************************************************************************************/

          $stateProvider             
              .state('app', {
                  abstract: true,
                  url: '',
                  template: '<div class="MainCtl" ui-view></div>',
                  controller: 'MainCtl',
                  data: {
                    requireLogin: false
                  },
                  resolve: {
                    deps: ['uiLoad', '$ocLazyLoad', function( uiLoad, $ocLazyLoad ){
                      return uiLoad.load([
                        'modules/users/userService.js',
                      ]).then(function(){
                        return $ocLazyLoad.load(['toaster']);
                      });
                    }]
                  }                  
              })

              .state('app.dashboard', {
                  abstract: true,
                  url: '/dashboard',              
                  templateUrl: 'tpl/layout.html',                         
                  resolve: {
                    deps: ['$ocLazyLoad', function( $ocLazyLoad ){
                      return $ocLazyLoad.load(['js/controllers/chart.js']);
                    }]
                  }
              })

              .state('app.dashboard.home', {
                  url: '/',              
                  templateUrl: 'tpl/app_dashboard_v1.html'
              })




/***************************************************************************************
 ACCESS
***************************************************************************************/


              .state('app.access', {
                  url: '/access',
                  template: '<div ui-view class="fade-in-right-big smooth"></div>'
              })


              .state('app.access.signin', {
                  url: '/signin',
                  templateUrl: 'tpl/page_signin.html',
                  data: {
                    requireLogin: false 
                  },
                  resolve: {
                    deps: ['uiLoad', function( uiLoad ){
                      return uiLoad.load( ['js/controllers/signin.js'] );
                    }]
                  }
              })
              .state('app.access.signup', {
                  url: '/signup',
                  templateUrl: 'tpl/page_signup.html',
                  data: {
                    requireLogin: false 
                  },
                  resolve: {
                    deps: ['uiLoad', function( uiLoad ){
                      return uiLoad.load( ['js/controllers/signup.js'] );
                    }]
                  }
              })
              .state('app.access.forgotpwd', {
                  url: '/forgotpwd',
                  templateUrl: 'tpl/page_forgotpwd.html',
                  data: {
                    requireLogin: false 
                  },                  
              })
              .state('app.access.404', {
                  url: '/404',
                  templateUrl: 'tpl/page_404.html',
                  data: {
                    requireLogin: false 
                  },                  
              })




/***************************************************************************************
 WORKSPACES
***************************************************************************************/

              .state('app.workspaces', {
                  abstract: true,
                  url: '/workspaces',
                  templateUrl: 'tpl/layout.html',
                  controller: 'WorkspaceCtl',
                  data: {
                    requireLogin: true 
                  },           
                  resolve: {
                    deps: ['uiLoad', '$ocLazyLoad', function( uiLoad, $ocLazyLoad ){
                        return uiLoad.load( [
                          'modules/workspaces/workspaceService.js',
                          'modules/workspaces/workspaces.js',
                          JQ_CONFIG.AWS,
                          JQ_CONFIG.moment,
                          JQ_CONFIG.mandrill
                        ])
                        .then(function(){
                          return $ocLazyLoad.load('ui.select');
                        });                            
                    }]
                  }                  
              })              

              .state('app.workspaces.home', {
                  url: '/',
                  data: {
                    requireLogin: true 
                  },                  
                  templateUrl: 'modules/workspaces/workspaces_home.html',
                  controller: 'WorkspaceListCtl'                    
              })         

              .state('app.workspaces.add', {
                  url: '/add',
                  templateUrl: 'modules/workspaces/workspace_add.html',
                  controller: 'WorkspaceAddCtl',
                  resolve: {
                    deps: ['uiLoad', '$ocLazyLoad', function( uiLoad, $ocLazyLoad ){
                        return $ocLazyLoad.load('textAngular');
                    }]
                  }                  
              })      

              .state('app.workspaces.edit', {
                  url: '/edit/:workspaceId',
                  templateUrl: 'modules/workspaces/workspace_add.html',
                  controller: 'WorkspaceEditCtl',
                  resolve: {
                    deps: ['$ocLazyLoad', function( $ocLazyLoad ){
                        return $ocLazyLoad.load('textAngular');
                    }]
                  }                  
              })                                    

              .state('app.workspaces.view', {
                  url: '/:workspaceId',
                  templateUrl: 'modules/workspaces/workspace_view.html',
                  controller: 'WorkspaceViewCtl',
                  resolve: {
                    deps: ['uiLoad', function( uiLoad ){
                        return uiLoad.load( JQ_CONFIG.AWS );                           
                    }]
                  }                        
              })          


              .state('app.workspaces.view.details', {
                  url: '/details',
                  views: {
                      'workspaceContent': {
                          templateUrl: 'modules/workspaces/workspace_details.html'
                      }
                  },                          
              }) 

              .state('app.workspaces.view.details.dashboard', {
                  url: '/dashboard',
                  //controller: 'WorkspaceDetailCtl',
                  templateUrl: 'modules/workspaces/details_dashboard.html'                         
              }) 


              .state('app.workspaces.view.details.projectSetup', {
                  url: '/projectSetup',
                  //controller: 'WorkspaceDetailCtl',
                  templateUrl: 'modules/workspaces/details_projectSetup.html',
                  controller: 'WorkspaceProjectSetupCtl',
                  resolve: {
                    deps: ['uiLoad', function( uiLoad ){ 
                        return uiLoad.load( ['modules/workspaces/WorkspaceProjectSetup.js']);   
                    }]
                  }                                            
              }) 



              .state('app.workspaces.view.file', {
                  url: '/file/:fileId',
                  views: {
                      'workspaceContent': {
                          templateUrl: 'modules/workspaces/workspace_file.html'
                      }
                  }               
              })

              .state('app.workspaces.projectSetup', {
                  url: '/:workspaceId/projectSetup',
                  templateUrl: 'modules/workspaces/workspace_setup.html',

              })              


/***************************************************************************************
 USERS
***************************************************************************************/

  
              .state('app.users', {
                  abstract: true,
                  url: '/users',
                  templateUrl: 'tpl/layout.html',
                  controller: 'UsersCtl',
                  data: {
                    requireLogin: true 
                  },           
                  resolve: {
                    deps: ['uiLoad', function( uiLoad ){
                        return uiLoad.load( [
                        
                          'modules/offices/officeService.js',
                          'modules/users/usersCtl.js',
                          JQ_CONFIG.moment ]);
                    }]
                  }                  
              })              

              .state('app.users.home', {
                  url: '/',
                  views: {
                      '': {
                          templateUrl: 'modules/users/users_home.html'
                      }
                  },
                  resolve: {
                    deps: ['$ocLazyLoad', function( $ocLazyLoad ){
                      return $ocLazyLoad.load('ui.select')
                        .then(function(){
                          return $ocLazyLoad.load('ngImgCrop');
                        });
                    }]
                  }                  
              })
      }
    ]
  );
