// config
String.prototype.mysqlToDate = String.prototype.mysqlToDate || function() {
  var t = this.split(/[- :]/);
  return new Date(t[0], t[1]-1, t[2], t[3]||0, t[4]||0, t[5]||0);
};


var app =  
angular.module('app')
  .config(
    [        '$controllerProvider', '$compileProvider', '$filterProvider', '$provide', '$httpProvider',
    function ($controllerProvider,   $compileProvider,   $filterProvider,   $provide, $httpProvider) {
        
        // lazy controller, directive and service
        app.controller = $controllerProvider.register;
        app.directive  = $compileProvider.directive;
        app.filter     = $filterProvider.register;
        app.factory    = $provide.factory;
        app.service    = $provide.service;
        app.constant   = $provide.constant;
        app.value      = $provide.value;


        // Use x-www-form-urlencoded Content-Type
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

        /**
         * The workhorse; converts an object to x-www-form-urlencoded serialization.
         * @param {Object} obj
         * @return {String}
         */ 
         var param = function(obj) {
          var query = '', name, value, fullSubName, subName, subValue, innerObj, i;

          for(name in obj) {
            value = obj[name];

            if(value instanceof Array) {
              for(i=0; i<value.length; ++i) {
                subValue = value[i];
                fullSubName = name + '[' + i + ']';
                innerObj = {};
                innerObj[fullSubName] = subValue;
                query += param(innerObj) + '&';
              }
            }
            else if(value instanceof Object) {
              for(subName in value) {
                subValue = value[subName];
                fullSubName = name + '[' + subName + ']';
                innerObj = {};
                innerObj[fullSubName] = subValue;
                query += param(innerObj) + '&';
              }
            }
            else if(value !== undefined && value !== null)
              query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
          }

          return query.length ? query.substr(0, query.length - 1) : query;
        };

        // Override $http service's default transformRequest
        $httpProvider.defaults.transformRequest = [function(data) {
          return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
        }]; 


    }
  ])


.run(function ($rootScope, $state, $stateParams, Auth, $window, authService, $http) {

  //handle unauthorized state changes
  $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {

    //check if login is required
    var requireLogin = false;
    if(typeof toState.data.requireLogin !== 'undefined'){
      requireLogin = toState.data.requireLogin;
    }

    //if login required and not logged in
    if (requireLogin && !Auth.loggedIn() ) {
      event.preventDefault();
      console.log('not logged in and its required for state ' + toState.name);
      
      //will need this after login to route to original requested state;
      $rootScope.toState = toState.name;
      $rootScope.toParams = toStateParams;
      
      return $state.go('app.access.signin');
    }

    //if roles are defined and user is already logged in
    var roles = [];
    if(typeof toState.data.roles !== 'undefined' && Auth.loggedIn() ){
      if( !Auth.authorize(toState.data.roles) ){
        //redirect to no auth page.
        return $state.go('app.access.unauthorized');
      }
    }
  });

  
  //Handle 401 responses from people who's php sessions have expired;
  $rootScope.$on('event:auth-loginRequired', function() {
    console.log('Unauthorized 401! Logging back in...');
    
    if(typeof $window.localStorage.currentUser !== 'undefined'){
      Auth.login(
          Auth.currentUser().email,
          Auth.currentUser().password
      )
        .then(function(){
          if(typeof $window.localStorage.currentUser !== 'undefined'){
            console.log('Login successful!');
            authService.loginConfirmed();
          } else {
            var confirm = $window.confirm('Your session has expired and your credentials are no longer valid. Please login again.');
              if(confirm == true){
                $state.go('app.access.signin');
              }
          }
        });
    }
  });  

});




