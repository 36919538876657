'use strict';

/* Filters */
// need load the moment.js to use this filter. 
angular.module('app').filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
          items.forEach(function(item) {
            var itemMatches = false;

            var keys = Object.keys(props);
            for (var i = 0; i < keys.length; i++) {
              var prop = keys[i];
              var text = props[prop].toLowerCase();
              if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                itemMatches = true;
                break;
              }
            }

            if (itemMatches) {
              out.push(item);
            }
          });
        } else {
          // Let the output be the input untouched
          out = items;
        }

        return out;
    };
})

angular.module('app')
  .filter('fromNow', function() {
    return function(date) {
      return moment(date).fromNow();
    }
  });

angular.module('app')
  .filter('fileIcon', function() {
    return function(filename) {

		if(filename){

			var ext = filename.split('.').pop();
			
			var iconClass = 'fa-file';
			
			switch(ext) {
			    case 'docx':
			        var iconClass = 'fa-file-word-o'
			        break;
			    
			    case 'doc':
			        var iconClass = 'fa-file-word-o'
			        break;
			    
			    case 'xls':
			        var iconClass = 'fa-file-excel-o'
			        break;

			    case 'xlsx':
			        var iconClass = 'fa-file-excel-o'
			        break;		        
			    
			    case 'pdf':
			        var iconClass = 'fa-file-pdf-o'
			        break;

			    case 'ppt':
			        var iconClass = 'fa-file-powerpoint-o'
			        break;

			    case 'pptx':
			        var iconClass = 'fa-file-powerpoint-o'
			        break;

			    case 'jpg':
			        var iconClass = 'fa-file-image-o'
			        break;

			    case 'jpeg':
			        var iconClass = 'fa-file-image-o'
			        break;

			    case 'png':
			        var iconClass = 'fa-file-image-o'
			        break;

			    case 'bmp':
			        var iconClass = 'fa-file-image-o'
			        break;

			    case 'gif':
			        var iconClass = 'fa-file-image-o'
			        break;

			    case 'txt':
			        var iconClass = ' fa-file-text-o'
			       break;

			    case 'zip':
			        var iconClass = 'fa-file-zip-o'
			        break;			       		        	        		        		        	        				        		        			        		        		        
			    
			    default:
			       var iconClass = 'fa-file-o';
			}

			return iconClass; 

		}

		return filename;
    
    }
  });

angular.module('app').filter('filesize', function () {
		return function (size) {
			if (isNaN(size))
				size = 0;
 
			if (size < 1024)
				return size + ' Bytes';
 
			size /= 1024;
 
			if (size < 1024)
				return size.toFixed(2) + ' Kb';
 
			size /= 1024;
 
			if (size < 1024)
				return size.toFixed(2) + ' Mb';
 
			size /= 1024;
 
			if (size < 1024)
				return size.toFixed(2) + ' Gb';
 
			size /= 1024;
 
			return size.toFixed(2) + ' Tb';
		};
	});
