// loginService.js

angular.module('app').factory('Auth', function ($rootScope, $window, $state, $http) {

  var service = {};

  service.login = function(email, password){
    var credentials = {email: email, password: password};
    return $http.post('/users/login', credentials)
      .success(function(data){
        if(data.status == "ok"){
          //set to localstorage
          $window.localStorage.currentUser = angular.toJson(data.user[0]);
          return true;          
        } else {
          delete $window.localStorage.currentUser;
          return false;
        }
      });
  };

  service.logout = function(){
    delete $window.localStorage.currentUser;
    console.log('user logged out');
    $http.post('/users/logout')
      .success(function(){
        //set to localstorage
        delete $window.localStorage.currentUser;
        $state.go('app.access.signin');
      });
  };

  service.loggedIn = function(){
    if(typeof $window.localStorage.currentUser !== 'undefined'){
      return true;
    } else {
      return false;
    }
  };

  service.authorize = function(roles){

    var userRole = $window.localStorage.currentUser.role;
    
    for (var i = 0; i < roles.length; i++) {
      if (roles.indexOf(userRole) != -1){
        return true;
      }
      return false;
    };
  }

  service.currentUser = function(){
    return angular.fromJson($window.localStorage.currentUser);
  };

  service.isAdmin = function(){
    if($window.localStorage.currentUser.type == 'admin'){
      return true;
    } else {
      return false;
    }

  };

  return service;

});